<template>
  <div>
    <div class="ma-6">
      <h2>{{ pageTitle }}</h2>
      <hr class="mb-4" />
      <div v-if="formDataAvailable">
        <div class="font-weight-bold mb-5">{{ currentGroup.headerName }}</div>
        <form>
          <div v-if="getComponentType(currentGroupElements[0]) !== 'RadioFormElement'">
            <component v-for="element in currentGroupElements" :key="element.id" :is="getComponentType(element)"
              :element="getElementWithAnswer(element)" @answer-updated="updateAnswers" ref="formElements"></component>
          </div>
          <div v-else>
            <component :is="getComponentType(currentGroupElements[0])"
              :elements="getElementsWithAnswers(currentGroupElements)" @answer-updated="updateAnswers"
              ref="formElements"></component>
          </div>
        </form>
      </div>
    </div>

    <div class="footer-message pa-1">
      <v-row>
        <v-container class="py-0 fill-height">
          <v-row class="pt-4 px-1">
            <div v-if="currentPageIndex < formGroups.length - 1" class="col-md-8"></div>
            <div class="col-md-2 col-6 links text-left">
              <v-btn large block v-if="currentPageIndex > 0" color="secondary" elevation="0" @click="prevPage">
                {{ $t("signer_form.previous_button") }}
              </v-btn>
            </div>
            <div class="col-md-2 col-6 links text-right">
              <v-btn large block v-if="currentPageIndex < formGroups.length - 1" color="secondary" elevation="0"
                @click="nextPage">
                {{ $t("signer_form.next_button") }}
              </v-btn>
            </div>
            <div v-if="currentPageIndex > 0" class="col-md-8"></div>
          </v-row>
          <div class="row" v-if="currentPageIndex === formGroups.length - 1">
            <div class="col-md-12 col-12 reject pr-0">
              <v-btn large color="#f36e21" class="sign-reject-btn w-100" block dark @click="submitAnswers()">
                {{ $t("signer_form.save_and_continue") }}
              </v-btn>
            </div>
          </div>
        </v-container>
      </v-row>
    </div>

    <v-snackbar v-model="submitError" :timeout="5000" color="red" variant="tonal">
      {{ submitErrorMessage }}
      <template v-slot:actions>
        <v-btn density="compact" icon="mdi-close" color="indigo" variant="text" @click="submitError = false"></v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import InputFormElement from '../shared/InputFormElement.component.vue';
import CheckboxFormElement from '../shared/CheckboxFormElement.component.vue';
import RadioFormElement from '../shared/RadioFormElement.component.vue';
import FormsService from '../../services/multiple/forms-service.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    InputFormElement,
    CheckboxFormElement,
    RadioFormElement
  },
  data() {
    return {
      answers: [],
      submitError: false,
      submitErrorMessage: null,
      token: null,
      authId: null,
      currentDocument: null,
      formToken: null,
      pageTitle: null
    };
  },
  computed: {
    formDataAvailable() {
      return this.formGroups.length > 0;
    },
    currentGroup() {
      return this.formGroups && this.formGroups.length > 0 && this.formGroups[this.currentPageIndex]
        ? this.formGroups[this.currentPageIndex].signerFormElement
        : {};
    },
    currentGroupElements() {
      const currentGroup = this.currentGroup;
      if (Object.keys(currentGroup).length === 0) {
        return [];
      } else {
        return currentGroup.nonExcludingElements.concat(currentGroup.excludingElements);
      }
    },
    currentPageIndex() {
      return this.$store.state.forms.currentPageIndex;
    },
    formGroups() {
      return this.$store.state.forms.formData;
    },
    hasAssociation() {
      return this.$store.getters['common/hasAssociation'];
    },
    currentRoute() {
      return this.$route;
    },
    documentCheckList() {
      return localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
    },
    getID() {
      return this.$route.params.id;
    },
    ...mapGetters('attachments', [
      'hasAttachments', 'getTransactionApiToken'
    ]),
    hasSignerForm() {
      return this.$store.getters['forms/hasSignerForm'];
    },
    hasPendingSignerForm() {
      return this.$store.getters['forms/hasPendingSignerForm'];
    }
  },
  methods: {
    async submitAnswers() {
      this.submitError = false;
      this.submitErrorMessage = null;
      let isFormValid = this.checkValidation();

      if (isFormValid) {
        
        // Ensure all form elements are included in the answers
        this.generateAnswerArray();

        // Ensure all checkboxes, radio buttons, and text inputs have a value
        this.answers.forEach(answer => {
            if (answer.signerFormElementType === 'CHECKBOX' && (answer.answer === null || answer.answer === undefined)) {
                answer.answer = false;
            } else if (answer.signerFormElementType === 'RADIO' && (answer.answer === null || answer.answer === undefined)) {
                answer.answer = false;
            } else if (answer.signerFormElementType === 'INPUT' && answer.answer === undefined) {
                answer.answer = null;
            }
        });


        try {
          let payload = {
            signerFormAnswerElements: this.answers
          };

          let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];

          await this.loadFormStatus(); // Wait for loadFormStatus to complete
          await FormsService.postAnswers(this.$route.params.id, payload, this.token, this.authId).then(() => {
            localStorage.setItem('formAnswers', JSON.stringify(this.answers)); // Save answers to localStorage

            for (let i = 0; i < documentCheckList.length; i++) {
              if (documentCheckList[i].documentId == this.$route.params.id) {
                documentCheckList[i].signerFormChecked = true;
                localStorage.setItem('documentCheckList', JSON.stringify(documentCheckList));

                // Check if there is a next element in the array
                if (i + 1 < documentCheckList.length) {

                  // Modify the next element here
                  if ((documentCheckList[i + 1].checkSignerForm) && (!documentCheckList[i + 1].signerFormChecked)) {
                    let newPath = this.$router.resolve({
                      name: `${this.hasAssociation ? 'custom-' : ''}multiple-forms`,
                      params: { id: documentCheckList[i + 1].documentId },
                      query: {
                        token: documentCheckList[i + 1].documentToken,
                        auth_id: documentCheckList[i + 1].auth_id,
                        document_name: documentCheckList[i + 1].documentName
                      }
                    }).href;

                    const extractBasePath = (path) => {
                      const segments = path.split('/');
                      // Assuming the ID is always after the base segment (e.g., /multiple-forms/ID)
                      if (segments.length > 2) {
                        return segments.slice(0, 2).join('/'); // Adjust index based on your URL structure
                      }
                      return path;
                    };

                    const currentBasePath = extractBasePath(this.$route.path);
                    const newBasePath = extractBasePath(new URL(newPath, window.location.origin).pathname);

                    if (currentBasePath === newBasePath) {
                      console.log('identical base paths');
                      // If the new path is the same as the current path, reload the page
                      window.location.href = newPath; // Changes the URL and reloads the page
                    }
                  }
                } else {
                  this.navigateFormToNext();
                }
              }
            }
            // if (localStorage.getItem('type') && ['ONETIME', 'ONETIMEWITHID'].includes(localStorage.getItem('type'))) {
            //   this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}pdf-viewer`, query: this.$route.query });
            // } else {
            //   this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}show-document`, params: { id: this.$route.params.id }, query: this.$route.query });
            // }
          }).catch(error => {
            console.log(error);
            // this.submitError = true;
            // this.submitErrorMessage = error.response.data === 'Form already answered' ? this.$t("signer_form.already_submitted") : this.$t("signer_form.submission_error");
         
         console.log(' ERROR IN NAVIGATING');
            this.navigateFormToNext();
          });
        } catch (error) {
          this.submitError = true;
          this.submitErrorMessage = this.$t("signer_form.submission_error");
        }
      }
    },
    updateAnswers(emittedAnswer) {
      if (emittedAnswer) {
        if (Array.isArray(emittedAnswer)) {
          emittedAnswer.forEach(answer => {
            this.storeAnswer(answer);
          });
        } else {
          this.storeAnswer(emittedAnswer);
        }

        console.log('Updated answers:', this.answers); // Debugging
      }
    },
    storeAnswer(emittedAnswer) {
      const answerIndex = this.answers.findIndex(answer => answer.id === emittedAnswer.id);

      if (answerIndex !== -1) {
        this.answers[answerIndex].answer = emittedAnswer.answer;
      } else {
        this.answers.push(emittedAnswer);
      }

      // Store updated answers in localStorage
      localStorage.setItem('formAnswers', JSON.stringify(this.answers));
    },
    prevPage() {
      localStorage.setItem('formAnswers', JSON.stringify(this.answers)); // Save current answers to localStorage
      this.$store.commit('forms/updateCurrentPage', this.currentPageIndex - 1);
    },
    nextPage() {
      let isFormValid = this.checkValidation();

      if (isFormValid) {
        localStorage.setItem('formAnswers', JSON.stringify(this.answers)); // Save current answers to localStorage
        this.$store.commit('forms/updateCurrentPage', this.currentPageIndex + 1);
      }
    },
    checkValidation() {
      let isFormValid = true;

      if (Array.isArray(this.$refs.formElements)) {
        this.$refs.formElements.forEach(formElement => {
          const isValid = formElement.validateField();
          if (!isValid) {
            isFormValid = false;
          }
        });
      } else {
        const isValid = this.$refs.formElements.validateField();
        if (!isValid) {
          isFormValid = false;
        }
      }

      return isFormValid;
    },
    getComponentType(element) {
      if (element.signerFormElementType === 'INPUT') {
        return 'InputFormElement';
      } else if (element.signerFormElementType === 'CHECKBOX' && !element.excluding) {
        return 'CheckboxFormElement';
      } else if (element.signerFormElementType === 'CHECKBOX' && element.excluding) {
        return 'RadioFormElement';
      }
    },
    getElementWithAnswer(element) {
      const answer = this.answers.find(ans => ans.id === element.id);
      return {
        ...element,
        answer: answer !== undefined ? answer.answer : element.signerFormElementType === 'INPUT' ? null : false
      };
    },
    getElementsWithAnswers(elements) {
      return elements.map(element => ({
        ...element,
        answer: this.answers.find(ans => ans.id === element.id)?.answer ?? (element.signerFormElementType === 'INPUT' ? null : false)
      }));
    },
    generateAnswerArray() {
      let elements = [];
      this.formGroups.forEach(groupData => {
        groupData.signerFormElement.excludingElements.forEach(element => {
          element.groupId = groupData.signerFormElement.id;
          elements.push(element);
        });

        groupData.signerFormElement.nonExcludingElements.forEach(element => {
          element.groupId = groupData.signerFormElement.id;
          elements.push(element);
        });
      });

           // Ensure all elements are present in the answers array with signerFormGroup
           this.answers = elements.map(element => {
            let answer = this.answers.find(ans => ans.id === element.id);
            if (!answer) {
                answer = {
                    id: element.id,
                    signerFormGroup: {
                        id: element.groupId
                    },
                    answer: element.signerFormElementType === 'INPUT' ? null : false,
                    signerFormElementType: element.signerFormElementType // Add the type for reference
                };
            } else if (!answer.signerFormGroup) {
                answer.signerFormGroup = {
                    id: element.groupId
                };
            }
            return answer;
        });
    },
    async findCurrentDocument() {
      try {
        await this.$store.dispatch('forms/getFormDetails', {
          requestId: this.$route.params.id,
          token: this.token,
          authId: this.authId
        });
      } catch (error) {
        console.error('Error fetching form details:', error);
      }
    },
    async loadFormStatus() {
      try {
        await this.$store.dispatch('forms/getMultipleFormStatus', {
          requestId: this.$route.params.id,
          token: this.token,
          authId: this.authId
        });
      } catch (error) {
        console.error('Error loading form status:', error);
      }
    },
    async loadQuestions() {
      try {
        const response = await this.$store.dispatch('forms/getMultipleFormStatus', {
          requestId: this.$route.params.id,
          token: this.token,
          authId: this.authId
        });
        console.log('Form Status Loaded:', response);
      } catch (error) {
        console.error('Error loading form status:', error);
      }
    },

    navigateFormToNext() {

      // if (localStorage.getItem('type') && ['ONETIME', 'ONETIMEWITHID'].includes(localStorage.getItem('type'))) {
      //         this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}pdf-viewer`, query: this.$route.query });
      //       } else {
      //         this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}show-document`, params: { id: this.$route.params.id }, query: this.$route.query });
      //       }
      // First, try to find a document with signerFormChecked as false and hasPendingSignerForm as true
      let matchingDocument = this.documentCheckList.find(document => !document.signerFormChecked && document.hasPendingSignerForm);

      // Define a variable to determine the route name
      let routeName = "";

      // If a matching document is found
      if (matchingDocument) {
        // Navigate to multiple-forms
        routeName = `${this.hasAssociation ? 'custom-' : ''}multiple-forms`;
      } else {
        // No matching document found, navigate to multiple-pdf
        routeName = `${this.hasAssociation ? 'custom-' : ''}multiple-pdf`;
      }
      if (!routeName) {
        // If routeName is not set, default to previous-information
        routeName = `${this.hasAssociation ? 'custom-' : ''}previous-information`;
      }

      // Handle the outcome based on the found document
      if (matchingDocument) {
    
        this.navigateToNext(matchingDocument, routeName);
      } else if (routeName) {
        // Navigate to routeName even without a matching document
        this.$router.push({ name: routeName });
      } else {
        console.log("No matching document found");
      }
    },
    navigateToNext(document, routeName) {
      // Resolve the new path using a dynamic route name
      const newPath = this.$router.resolve({
        name: routeName,
        params: { id: document.documentId },
        query: {
          token: document.documentToken,
          auth_id: document.auth_id,
          document_name: document.documentName
        }
      }).href;

      const extractBasePath = (path) => {
        const segments = path.split('/');
        if (segments.length > 2) {
          return segments.slice(0, 2).join('/'); // Adjust index based on your URL structure
        }
        return path;
      };

      const currentBasePath = extractBasePath(this.$route.path);
      const newBasePath = extractBasePath(new URL(newPath, window.location.origin).pathname);

      if (currentBasePath === newBasePath) {
        // If the new path is the same as the current path, reload the page
        window.location.href = newPath;
      } else {
        // Otherwise, use Vue Router to navigate with the dynamic route name
        this.$router.push({
          name: routeName,
          params: { id: document.documentId },
          query: {
            token: document.documentToken,
            auth_id: document.auth_id,
            document_name: document.documentName
          }
        });
      }
    },
    navigateToFinal(document, routeName) {
      // Resolve the new path using a dynamic route name
      const newPath = this.$router.resolve({
        name: routeName,
        params: { id: document.documentId },
        query: {
          token: document.documentToken,
          auth_id: document.auth_id,
          document_name: document.documentName
        }
      }).href;
      window.location.href = newPath;
    },
    checkDocumentStatus() {
      let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
      let hasPendingForm = false;
      let routeName = "";

      documentCheckList.forEach((document) => {
        if (document.documentId == this.documentId) {
          if (document.hasPendingSignerForm) {
            hasPendingForm = true;
          }
        }
      });
      if (!hasPendingForm) {
        routeName = `${this.hasAssociation ? 'custom-' : ''}multiple-pdf`;
        this.$router.push({ name: routeName });
      }
    }

  },
  async created() {
    localStorage.removeItem('formAnswers');
    this.token = this.$route.query.token || null;
    this.authId = this.$route.query.auth_id || null;

    // this.checkDocumentStatus();

    let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
    for (let i = 0; i < documentCheckList.length; i++) {
      if (documentCheckList[i].documentId == this.$route.params.id) {
        this.pageTitle = documentCheckList[i].documentName;
      }
    }
    this.loadFormStatus();
    this.loadQuestions();
    if (this.currentRoute && this.currentRoute.params.id && this.token) {
      await this.findCurrentDocument();
    }
    // this.answers = JSON.parse(localStorage.getItem('formAnswers')) || [];
  }
};
</script>
